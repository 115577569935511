export const toCurrency = (number = 0) => {
  if (!number) return 0;

  if (typeof number === "number")
    return `Rp ${parseInt(number).toLocaleString("id-ID")}`;

  return 0;
};

export const convertToISO8601 = (dateString) => {
  // Create a new Date object by adding the seconds (05) and 'Z' for UTC time
  const date = new Date(`${dateString}:05Z`);
  // Return the date in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ)
  return date.toISOString();
};

export const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)
