import React from 'react'

export default function AddIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16669 10.8333H5.00002C4.76391 10.8333 4.56599 10.7534 4.40627 10.5937C4.24655 10.434 4.16669 10.2361 4.16669 9.99996C4.16669 9.76385 4.24655 9.56593 4.40627 9.40621C4.56599 9.24649 4.76391 9.16663 5.00002 9.16663H9.16669V4.99996C9.16669 4.76385 9.24655 4.56593 9.40627 4.40621C9.56599 4.24649 9.76391 4.16663 10 4.16663C10.2361 4.16663 10.434 4.24649 10.5938 4.40621C10.7535 4.56593 10.8334 4.76385 10.8334 4.99996V9.16663H15C15.2361 9.16663 15.434 9.24649 15.5938 9.40621C15.7535 9.56593 15.8334 9.76385 15.8334 9.99996C15.8334 10.2361 15.7535 10.434 15.5938 10.5937C15.434 10.7534 15.2361 10.8333 15 10.8333H10.8334V15C10.8334 15.2361 10.7535 15.434 10.5938 15.5937C10.434 15.7534 10.2361 15.8333 10 15.8333C9.76391 15.8333 9.56599 15.7534 9.40627 15.5937C9.24655 15.434 9.16669 15.2361 9.16669 15V10.8333Z"
        fill="currentColor"
      />
    </svg>
  );
}
