import React from 'react'

export default function DownloadIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 12.9792C9.88905 12.9792 9.78488 12.9618 9.68766 12.9271C9.59044 12.8924 9.50016 12.8334 9.41683 12.75L6.41683 9.75004C6.25016 9.58337 6.1703 9.38893 6.17725 9.16671C6.18419 8.94449 6.26405 8.75004 6.41683 8.58337C6.5835 8.41671 6.78141 8.3299 7.01058 8.32296C7.23975 8.31601 7.43766 8.39587 7.60433 8.56254L9.16683 10.125V4.16671C9.16683 3.9306 9.24669 3.73268 9.40641 3.57296C9.56613 3.41324 9.76405 3.33337 10.0002 3.33337C10.2363 3.33337 10.4342 3.41324 10.5939 3.57296C10.7536 3.73268 10.8335 3.9306 10.8335 4.16671V10.125L12.396 8.56254C12.5627 8.39587 12.7606 8.31601 12.9897 8.32296C13.2189 8.3299 13.4168 8.41671 13.5835 8.58337C13.7363 8.75004 13.8161 8.94449 13.8231 9.16671C13.83 9.38893 13.7502 9.58337 13.5835 9.75004L10.5835 12.75C10.5002 12.8334 10.4099 12.8924 10.3127 12.9271C10.2154 12.9618 10.1113 12.9792 10.0002 12.9792ZM5.00016 16.6667C4.54183 16.6667 4.14947 16.5035 3.82308 16.1771C3.49669 15.8507 3.3335 15.4584 3.3335 15V13.3334C3.3335 13.0973 3.41336 12.8993 3.57308 12.7396C3.7328 12.5799 3.93072 12.5 4.16683 12.5C4.40294 12.5 4.60086 12.5799 4.76058 12.7396C4.9203 12.8993 5.00016 13.0973 5.00016 13.3334V15H15.0002V13.3334C15.0002 13.0973 15.08 12.8993 15.2397 12.7396C15.3995 12.5799 15.5974 12.5 15.8335 12.5C16.0696 12.5 16.2675 12.5799 16.4272 12.7396C16.587 12.8993 16.6668 13.0973 16.6668 13.3334V15C16.6668 15.4584 16.5036 15.8507 16.1772 16.1771C15.8509 16.5035 15.4585 16.6667 15.0002 16.6667H5.00016Z"
        fill="currentColor"
      />
    </svg>
  );
}
