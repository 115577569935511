import React, { useContext, useEffect, useState } from 'react';
import { Modal, Pagination, Select, Table } from 'antd';
// import { NavLink } from "react-router-dom";
import LinkExternalIcon from '../../assets/icons/LinkExternalIcon';
import { toCurrency } from '../../utils/String';
import { render } from '@testing-library/react';
import dayjs from 'dayjs';
import { getListRefrensi } from '../../api/PriceEstimation';
// import { GlobalContext } from '../../contexts/GlobalStateContext';
import { useQuery } from 'react-query';

const ModalDetailRefrensi = ({isModalOpen, setIsModalOpen, idPriceEstimate, setInvoiceData = () => {}}) => {
    // const { idPriceEstimate } = useContext(GlobalContext);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const [filter, setFilter] = useState({
        search: ''
    })

    const { data } = useQuery(
        ['getListRefrensi', idPriceEstimate, filter],
        () => getListRefrensi(idPriceEstimate, filter),
        {
            enabled: true,
        }
    );

    const handlePageChange = (page, pageSize) => {
        setCurrentPage(page);
    };

    const invoiceColumns = [
        {
            title: "Invoice No",
            dataIndex: "invoiceNo",
            key: "invoiceNo",
            sorter: (a, b) => a.invoiceNo.length - b.invoiceNo.length,
            showSorterTooltip: false,
        },
        {
            title: "Invoice Date",
            dataIndex: "invoiceDate",
            key: "invoiceDate",
            sorter: (a, b) => a.invoiceDate.length - b.invoiceDate.length,
            showSorterTooltip: false,
            render: (_, record) => dayjs(record.invoiceDate).format("DD MMM YYYY, HH:mm")
            
        },
        {
            title: "Pasien",
            dataIndex: "patient",
            key: "patient",
            sorter: (a, b) => a.patient.length - b.patient.length,
            showSorterTooltip: false,
        },
        {
            title: "Nama Prosedur",
            dataIndex: "surgery",
            key: "surgery",
            sorter: (a, b) => a.surgery.length - b.surgery.length,
            showSorterTooltip: false,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            sorter: (a, b) => a.amount.length - b.amount.length,
            showSorterTooltip: false,
            render: (_, record) => toCurrency(record.amount),
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                // console.log('record moodal', record)
                <a href={`/invoice/${record.id}`} target="_blank" rel="noopener noreferrer">
                    <LinkExternalIcon />
                </a>
            ),
        },
    ];

    const datas = data?.data?.map((item) => (
        {
            key: item?.invoice?.id,
            id: item?.invoice?.id,
            admissionDate: item?.created_at,
            amount: item?.invoice?.total,
            invoiceDate: item?.invoice?.invoice_date,
            invoiceNo: item?.invoice?.invoice_no,
            patient: item?.invoice?.patient,
            surgery: item?.invoice?.operation_name
        }
    ));
   

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setInvoiceData(data?.data?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ data ])

    return(
        <>
            <Modal 
                title={<div className="sl-text-xl">Refrensi</div>}
                centered
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                width="800px"
            >
                <div className="sl-w-full sl-flex sl-flex-wrap sl-space-y-5 sl-my-10">
                    <div className="sl-w-full">
                        <div className="sl-flex sl-items-center sl-justify-start sl-p-4 sl-bg-surface-success-light sl-rounded-lg">
                            <div>
                                This cost estimate was obtained based on <span className="sl-font-medium">{data?.data?.length}</span> existing invoice references.
                            </div>
                        </div>
                    </div>
                    <div className="sl-w-full">
                        <div className="sl-relative sl-w-full">
                            <input
                                type="text"
                                className="sl-w-full sl-pl-5 sl-pr-4 sl-py-2 sl-border sl-rounded-md sl-focus:outline-none sl-focus:ring-2 sl-focus:ring-blue-500"
                                placeholder="Search"
                                onChange={(e) =>
                                  setFilter({ ...filter, search: e.target.value })
                                }
                            />
                            <div className="sl-absolute sl-inset-y-0 sl-right-5 sl-flex sl-items-center sl-pl-3">
                                <svg
                                    className="sl-w-5 sl-h-5 sl-text-gray-500"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M21 21l-4.35-4.35m0 0A7.5 7.5 0 1115 3.5a7.5 7.5 0 011.65 14.15z"
                                    ></path>
                                </svg>
                            </div>
                            
                        </div>
                    </div>
                    <div className="sl-w-full">
                        <Table
                            className="result-table"
                            columns={invoiceColumns}
                            dataSource={datas}
                            pagination={false}
                            footer={() => (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                <div>
                                    Display{" "}
                                    <Select
                                        className="sl-w-[80px]"
                                        defaultValue={10}
                                        options={[
                                            {
                                            value: 10,
                                            label: "10",
                                            },
                                            {
                                            value: 20,
                                            label: "20",
                                            },
                                            {
                                            value: 50,
                                            label: "50",
                                            },
                                            {
                                            value: 100,
                                            label: "100",
                                            },
                                        ]}
                                    />{" "}
                                    of {data?.meta?.total} data.
                                </div>

                                <Pagination
                                    current={currentPage}
                                    pageSize={pageSize}
                                    total={data?.meta?.total}
                                    onChange={handlePageChange}
                                />
                                </div>
                            )}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ModalDetailRefrensi