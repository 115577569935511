import { axiosGlobal } from "../utils/AxiosConfig.js"

export const getListRole = async (params) => {
    try {
        const URL = `company-roles`;
        const response = await axiosGlobal.get(URL, {
            params: params
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getDetailRole = async (id) => {
    try {
        const URL = `company-roles/${id}`;
        const response = await axiosGlobal.get(URL);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getListMenus = async () => {
    try {
        const URL = `menus?sort=created_at`;
        const response = await axiosGlobal.get(URL);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createRole = async (payload) => {
    try {
        const URL = `company-roles`;
        const response = await axiosGlobal.post(URL, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteRole = async (id) => {
    try {
        const URL = `company-roles/${id}`;
        const response = await axiosGlobal.delete(URL);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateRole = async (id, payload) => {
    try {
        const URL = `company-roles/${id}`;
        const response = await axiosGlobal.put(URL, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};