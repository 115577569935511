import React from "react";
import { lazy } from "react";
import ProtectedRoute from './protectedRoute';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "../layouts/Main.js";
import DetailLayout from "../layouts/Detail.js";
import RoleManagementList from "../pages/RoleManagement/RoleManagementList.js";
import EvaluationList from "../pages/Evaluation/EvaluationList.js";
import EvaluationDetail from "../pages/Evaluation/EvaluationDetail.js";

const PriceEstimationList = lazy(() =>
  import("../pages/PriceEstimation/List.js")
);

const FormPriceEstimation = lazy(() =>
  import("../pages/PriceEstimation/Form.js")
);

const DetailPriceEstimation = lazy(() =>
  import("../pages/PriceEstimation/Detail.js")
);

const EditPriceEstimation = lazy(() =>
  import("../pages/PriceEstimation/EditPriceEstimation.js")
);

// const DetailEstimasiDaftarHarga = lazy(() =>
//   import("../pages/PriceEstimation/DetailEstimasiDaftarHarga.js")
// );

const MasterDataList = lazy(() =>
  import("../pages/MasterData/List.js")
);

const InvoiceList = lazy(() =>
  import("../pages/Invoice/List.js")
);

const DetailInvoice = lazy(() =>
  import("../pages/Invoice/Detail.js")
);

const UserManagement = lazy(() =>
  import("../pages/UserManagement/UserManagementList.js")
);
const CreateRole = lazy(() =>
  import("../pages/RoleManagement/CreateRole.js")
);
const EditRole = lazy(() =>
  import("../pages/RoleManagement/EditRole.js")
);

const Login = lazy(() =>
  import("../pages/Auth/Login.js")
);

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        element: <PriceEstimationList />,
      },
      {
        path: "/master-data",
        element: <MasterDataList />,
      },
      {
        path: "/invoice",
        element: <InvoiceList />,
      },
      {
        path: "/evaluation",
        element: <EvaluationList />,
      },
      {
        path: "/user-management",
        element: <UserManagement />,
      },
      {
        path: "/role-management",
        element: <RoleManagementList />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <DetailLayout />
      </ProtectedRoute>
    ),
    children: [
      // {
      //   path: "/estimasi-daftar-harga/:id",
      //   element: <DetailEstimasiDaftarHarga />,
      // },
      {
        path: "/price-estimation/:id",
        element: <DetailPriceEstimation />,
      },
      {
        path: "/edit-price-estimation/:id",
        element: <EditPriceEstimation />,
      },
      {
        path: "/price-estimation/:id/edit",
        element: <FormPriceEstimation />,
      },
      {
        path: "/price-estimation/create",
        element: <FormPriceEstimation />,
      },
      {
        path: "/invoice/:id",
        element: <DetailInvoice />,
      },
      {
        path: "/evaluation/:id",
        element: <EvaluationDetail />,
      },
      {
        path: "/role-management/create-role",
        element: <CreateRole />,
      },
      {
        path: "/role-management/edit-role/:id",
        element: <EditRole />,
      },
    ],
  },
]);

const RouterApp = () => {
  return <RouterProvider router={router} />;
};

export default RouterApp;
