import React from 'react'

export default function FolderIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33366 16.6666C2.87533 16.6666 2.48296 16.5034 2.15658 16.177C1.83019 15.8506 1.66699 15.4583 1.66699 14.9999V4.99992C1.66699 4.54159 1.83019 4.14922 2.15658 3.82284C2.48296 3.49645 2.87533 3.33325 3.33366 3.33325H7.64616C7.86838 3.33325 8.08019 3.37492 8.28158 3.45825C8.48296 3.54159 8.66005 3.65964 8.81283 3.81242L10.0003 4.99992H16.667C17.1253 4.99992 17.5177 5.16311 17.8441 5.4895C18.1705 5.81589 18.3337 6.20825 18.3337 6.66659V14.9999C18.3337 15.4583 18.1705 15.8506 17.8441 16.177C17.5177 16.5034 17.1253 16.6666 16.667 16.6666H3.33366ZM3.33366 14.9999H16.667V6.66659H9.31283L7.64616 4.99992H3.33366V14.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
