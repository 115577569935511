import React from "react"

export default function ArrowDown({ className }) {
    return (
        <svg
            className={className}
            width="8"
            height="5"
            viewBox="0 0 8 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.86198 0.195312L3.99998 3.05731L1.13798 0.195312L0.195312 1.13798L3.99998 4.94265L7.80465 1.13798L6.86198 0.195312Z"
                fill="currentColor"
            />
        </svg>
    )
}
