import React, { useState } from 'react';
import { Modal } from 'antd';
import ModalUpdateProfile from './ModalUpdateProfile';
import { getProfile } from '../../api/Profile';
import { useQuery } from 'react-query';

const ModalDetailProfile = ({ 
    openModalDetailProfile, 
    setOpenModalDetailProfile
}) => {
    const [openModalUpdateProfile, setOpenModalUpdateProfile] = useState(false);

    const { data, refetch } = useQuery(
        ['getProfile' ],
        () => getProfile(),
        {
            enabled: true,
        }
    );

    return (
        <>
            <Modal
                title={
                    <>
                    <div className="sl-flex sl-flex-wrap sl-items-center sl-gap-2">
                        <span className="sl-font-semibold sl-text-xl">Profile</span>
                        <button 
                            onClick={() => {setOpenModalUpdateProfile(!openModalUpdateProfile)}}
                            className="sl-text-sm sl-border sl-bg-border-primary-dark sl-border-border-primary-dark sl-rounded-md sl-px-2 sl-py-1"
                        >
                            Edit Biodata
                        </button>
                    </div>

                    </>
                }
                centered
                open={openModalDetailProfile}
                onCancel={() => setOpenModalDetailProfile(false)}
                footer={null}
                width="400px"
                className="sl-my-5"
            >
                <div>
                    <div className="sl-space-y-3 sl-my-7">
                        <div className="sl-w-full sl-flex sl-flex-wrap">
                            <div className="sl-w-1/5">
                                <label className="sl-text-text-secondary">Name</label>
                            </div>
                            <div className="sl-w-4/5">
                                <p className="sl-font-normal sl-text-base">{data?.data?.name || "-"}</p>
                            </div>
                        </div>
                        <div className="sl-w-full sl-flex sl-flex-wrap">
                            <div className="sl-w-1/5">
                                <label className="sl-text-text-secondary">Email</label>
                            </div>
                            <div className="sl-w-4/5">
                                <p className="sl-font-normal sl-text-base">{data?.data?.email || "-"}</p>
                            </div>
                        </div>
                        <div className="sl-w-full sl-flex sl-flex-wrap">
                            <div className="sl-w-1/5">
                                <label className="sl-text-text-secondary">Phone</label>
                            </div>
                            <div className="sl-w-4/5">
                                <p className="sl-font-normal sl-text-base">{data?.data?.phone || "-"}</p>
                            </div>
                        </div>
                        <div className="sl-w-full sl-flex sl-flex-wrap">
                            <div className="sl-w-1/5">
                                <label className="sl-text-text-secondary">Role</label>
                            </div>
                            <div className="sl-w-4/5">
                                <p className="sl-font-normal sl-text-base">{data?.data?.company_role || "-"}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <ModalUpdateProfile 
                refetch={refetch}
                openModalUpdateProfile={openModalUpdateProfile} 
                setOpenModalUpdateProfile={setOpenModalUpdateProfile} 
            />
        </>
    );
};

export default ModalDetailProfile;
