import { axiosGlobal } from "../utils/AxiosConfig.js"

export const createPriceEstimation = async (payload) => {
    try {
        const URL = `price-estimations`
        const response = await axiosGlobal.post(URL, payload)
        console.log("response", response)
        return response?.data
    } catch (error) {
        return error
    }
}

export const savePriceEstimation = async (id, payload) => {
    try {
        const URL = `price-estimations/${id}/status`
        const response = await axiosGlobal.put(URL, payload)
        return response?.data
    } catch (error) {
        return error
    }
}

export const editRincianBiaya = async (id, payload) => {
    try {
        const URL = `price-estimations/${id}`
        const response = await axiosGlobal.put(URL, payload)
        return response?.data
    } catch (error) {
        return error
    }
}

export const deletePriceEstimation = async (id, payload) => {
    try {
        const URL = `price-estimations/${id}`
        const response = await axiosGlobal.put(URL, payload)
        return response?.data
    } catch (error) {
        return error
    }
}

export const getResultPriceEstimation = async (id) => {
    try {
        const URL = `price-estimations/${id}`
        const response = await axiosGlobal.get(URL)
        return response?.data
    } catch (error) {
        return error
    }
}

export const getResultDetailEstimasi = async (id, serviceClass) => {
  try {
    const URL = `price-estimations/${id}?service_class=${encodeURIComponent(serviceClass)}`;
    const response = await axiosGlobal.get(URL);
    return response?.data;
  } catch (error) {
    console.error("Error fetching estimation details:", error);
    throw error; // Throw the error to be handled by the caller
  }
};

export const switchServiceClass = async (id, payload) => {
  try {
    const URL = `price-estimations/${id}/switch-service-classes`;
    const response = await axiosGlobal.put(URL, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const saveEditPriceEstimation = async (id, payload) => {
  try {
    const URL = `price-estimations/${id}`;
    const response = await axiosGlobal.put(URL, payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const getListPriceEstimate = async (params) => {
    const { data: response } = await axiosGlobal.get("price-estimations", {
        params: params
    })

    return response
}

export const getListDaftarEstimasiHarga = async (id) => {
    try {
        const URL = `price-estimations/${id}/service-classes`
        const response = await axiosGlobal.get(URL)
        return response?.data
    } catch (error) {
        return error
    }
}

export const getListRefrensi = async (id, filter) => {
    try {
        const URL = `price-estimations/${id}/invoices?search=${filter?.search}`
        const response = await axiosGlobal.get(URL)
        return response?.data
    } catch (error) {
        return error
    }
}

export const getListVersion = async (id, filter) => {
    try {
        const URL = `price-estimations/${id}/versions?search=${filter?.search}`
        const response = await axiosGlobal.get(URL)
        return response?.data
    } catch (error) {
        return error
    }
}

export const priceEstimationDelete = async (id) => {
    try {
        const URL = `price-estimations/${id}`
        const response = await axiosGlobal.delete(URL)
        return response?.data
    } catch (error) {
        return error
    }
}

export const getListItem = async (category) => {
  try {
    const URL = `items?category=${category}`;
    const response = await axiosGlobal.get(URL);
    return response?.data;
  } catch (error) {
    throw error;
  }
};
