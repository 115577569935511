import React, { 
  Suspense, 
  useState, 
  useEffect, 
  useRef 
} from "react";
import { Layout } from "antd";
import { NavLink, Outlet } from "react-router-dom";
import LogoutIcon from "../assets/icons/LogoutIcon";
import LeftPanelOpenIcon from "../assets/icons/LeftPanelOpenIcon";
import CurrencyExchangeIcon from "../assets/icons/CurrencyExchangeIcon";
import FolderIcon from "../assets/icons/FolderIcon";
import ReceiptIcon from "../assets/icons/ReceiptIcon";
import RoleManagementIcon from "../assets/icons/RoleManagement";
import UserManagementIcon from '../assets/icons/UserManagement';
import { clearLocalStorageData } from '../utils/AuthUtils';
import ImageProfile from '../assets/images/profile.png'
import ArrowDown from '../assets/icons/ArrowDown';
import ProfileIcon from '../assets/icons/ProfileIcon';
import ModalDetailProfile from '../components/Profile/ModalDetailProfile';
// import Logo from "../assets/images/logo.png";
// import NavButton from "../components/Common/NavButton";
// import NotificationIcon from "../assets/icons/NotificationIcon";
// import GitCompareIcon from "../assets/icons/GitCompareIcon";

const { Header, Content, Sider } = Layout;

const PopConfirm = ({ 
  onCancel, 
  openModalDetailProfile,
  setOpenModalDetailProfile 
}) => {
  const popconfirmRef = useRef(null);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popconfirmRef.current &&
        !popconfirmRef.current.contains(event.target)
      ) {
        onCancel();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onCancel]);

  const handleLogout = () => {
    clearLocalStorageData();
    window.location.href = '/login';
  };

  return(
    <>
      <div 
        ref={popconfirmRef}
        className="sl-w-48 sl-p-4 sl-absolute sl-z-50 sl-bg-white sl-right-0 sl-top-12 sl-rounded-lg sl-shadow-xl sl-border-surface-info-light"
      >
          <div className="sl-w-full sl-flex sl-flex-wrap sl-gap-y-3"> 
            <div 
              onClick={() => {setOpenModalDetailProfile(!openModalDetailProfile)}} 
              className="sl-w-full sl-inline-flex sl-gap-2 sl-items-center sl-cursor-pointer"
            >
              <ProfileIcon />
              <span>Profile</span>
            </div>
            <div onClick={handleLogout} className="sl-w-full sl-inline-flex sl-gap-2 sl-items-center sl-cursor-pointer">
              <LogoutIcon />
              <span>Logout</span>
            </div>
          </div>
      </div>
    </>
  )
}

export default function MainLayout() {
  const name = localStorage.getItem("name");
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  const [isPopconfirmVisible, setPopconfirmVisible] = useState(false);
  const [openModalDetailProfile, setOpenModalDetailProfile] = useState(false);

  const fixedSidebar = () => {
    setCollapsed(!collapsed);
    setOpen(false);
  };

  const showSidebar = () => {
    if (!collapsed) {
      setOpen(true);
    }

    return;
  };

  const hideSidebar = () => {
    if (!collapsed) {
      setOpen(false);
    }

    return;
  };

  const handleOpenModal = () => {
    setPopconfirmVisible(!isPopconfirmVisible);
  };

  const handleCloseModal = () => {
    setPopconfirmVisible(false);
  };

  const sidebarMenu = [
    {
      key: 1,
      label: "Price Estimation",
      to: "/",
      icon: <CurrencyExchangeIcon />,
    },
    {
      key: 2,
      label: "Master Data",
      to: "/master-data",
      icon: <FolderIcon />,
    },
    {
      key: 3,
      label: "Invoice",
      to: "/invoice",
      icon: <ReceiptIcon />,
    },
    // {
    //   key: 4,
    //   label: "Evaluation",
    //   to: "/evaluation",
    //   icon: <GitCompareIcon />,
    // },
    {
      key: 5,
      label: "User Management",
      to: "/user-management",
      icon: <UserManagementIcon />,
    },
    {
      key: 6,
      label: "Role Management",
      to: "/role-management",
      icon: <RoleManagementIcon />,
    },
  ];

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Header className="sl-flex sl-items-center sl-justify-between sl-h-[48px] sl-py-0 sl-pr-4 sl-pl-0 sl-fixed sl-w-full sl-z-[51]">
          <div className="sl-flex sl-items-center">
            <div
              className="sl-text-white sl-p-[14px] sl-border-r sl-border-r-[rgba(229,231,235,0.3)] sl-cursor-pointer"
              onClick={fixedSidebar}
            >
              <LeftPanelOpenIcon className="sl-w-5 sl-h-5" />
            </div>
            {/* <img src={Logo} alt="Siloam Logo" className="sl-ml-2" /> */}
          </div>

          <div className="sl-flex sl-items-center sl-gap-4">
            {/* <Button className="sl-w-12 sl-h-6 sl-rounded-full sl-font-semibold sl-bg-surface-info-light sl-text-text-info">
              POC
            </Button>
            <NavButton>
              <NotificationIcon />
            </NavButton>
            <NavButton onClick={handleLogout}>
              <LogoutIcon className="sl-w-5 sl-h-5" />
            </NavButton> */}
            <div onClick={handleOpenModal} className="sl-flex sl-flex-wrap sl-items-center sl-gap-2 sl-cursor-pointer">
              <div>
                <img
                    src={ImageProfile}
                    alt="logo_siloam"
                    className="sl-w-auto"
                />
              </div>
              <div className="sl-text-white">{name}</div>
              <div><ArrowDown className="sl-text-white sl-mt-1" /></div>
            </div>
          </div>

          
        </Header>

        <Layout className="sl-relative sl-pt-[48px]">
          <Sider
            className="sl-p-2"
            width={`${collapsed || open ? "200px" : "48px"}`}
            style={{
              backgroundColor: "#FFFFFF",
              border: "1px solid rgba(229, 231, 235, 1)",
              position: !collapsed ? "fixed" : "relative",
              height: !collapsed ? "100%" : "95vh",
              zIndex: 50,
            }}
            onMouseEnter={showSidebar}
            onMouseLeave={hideSidebar}
          >
            {!collapsed && !open && (
              <div className="sl-flex sl-flex-col sl-justify-center sl-items-center sl-gap-2">
                {sidebarMenu.map((v) => (
                  <NavLink
                    className={({ isActive }) =>
                      (isActive
                        ? "sl-bg-surface-brand-dark sl-text-white sl-rounded "
                        : "") + "sl-p-[6.83px]"
                    }
                    key={v.key}
                    to={v.to}
                  >
                    {v.icon}
                  </NavLink>
                ))}
              </div>
            )}

            {(collapsed || open) && (
              <div className="sl-flex sl-flex-col sl-justify-center sl-gap-2">
                {sidebarMenu.map((v) => (
                  <NavLink
                    key={v.key}
                    to={v.to}
                    className={({ isActive }) =>
                      (isActive
                        ? "sl-bg-surface-brand-dark sl-text-white "
                        : "") +
                      "sl-flex sl-items-center sl-gap-2 sl-px-[12px] sl-py-[6px] sl-rounded"
                    }
                  >
                    <div>{v.icon}</div>
                    <div className="sl-font-semibold">{v.label}</div>
                  </NavLink>
                ))}
              </div>
            )}
          </Sider>

          <Content
            className={`sl-p-8 sl-bg-white ${
              collapsed ? "sl-mr-0" : "sl-ml-[48px]"
            }`}
          >
            <Suspense fallback="...">
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>

      {isPopconfirmVisible && (
        <PopConfirm 
          onCancel={handleCloseModal}
          openModalDetailProfile={openModalDetailProfile}
          setOpenModalDetailProfile={setOpenModalDetailProfile}
        />
      )}

      <ModalDetailProfile 
        openModalDetailProfile={openModalDetailProfile}
        setOpenModalDetailProfile={setOpenModalDetailProfile} 
      />
    </>
  );
}
