import React, { useEffect, useState } from "react"
import { Button, DatePicker, Input, Pagination, Select, Table } from "antd"
import { toCurrency } from "../../utils/String.js"
import SearchIcon from "../../assets/icons/SearchIcon.js"
import { useQuery } from "react-query"
import { getServiceClass } from "../../api/MasterData.js"
import { NavLink, useSearchParams } from "react-router-dom"
import dayjs from "dayjs"

const { RangePicker } = DatePicker

export default function EvaluationList() {
    const columns = [
        {
            title: "Invoice No",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id.length - b.id.length,
            showSorterTooltip: false
        },
        {
            title: "Invoice Date",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.length - b.name.length,
            showSorterTooltip: false
        },
        {
            title: "Admission Date",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.name.length - b.name.length,
            showSorterTooltip: false
        },
        {
            title: "Pasien",
            dataIndex: "description",
            key: "description",
            sorter: (a, b) => a.description.length - b.description.length,
            showSorterTooltip: false
        },
        {
            title: "Doctor",
            dataIndex: "description",
            key: "description",
            sorter: (a, b) => a.description.length - b.description.length,
            showSorterTooltip: false
        },
        {
            title: "PIC",
            dataIndex: "description",
            key: "description",
            sorter: (a, b) => a.description.length - b.description.length,
            showSorterTooltip: false
        },
        {
            title: "EMR",
            dataIndex: "description",
            key: "description",
            sorter: (a, b) => a.description.length - b.description.length,
            showSorterTooltip: false
        },
        {
            title: "Nama Prosedur",
            dataIndex: "description",
            key: "description",
            sorter: (a, b) => a.description.length - b.description.length,
            showSorterTooltip: false
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <NavLink to={`/evaluation/298`} className="sl-flex sl-items-center sl-gap-2">
                    <Button className="sl-font-semibold">Evaluation</Button>
                </NavLink>
            )
        }
    ]

    const [searchParams, setSearchParams] = useSearchParams()
    const page = parseInt(searchParams.get("page") || "1", 10)
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10)
    const sortField = searchParams.get("sortField") || ""
    const sortOrder = searchParams.get("sortOrder") || ""
    const search = searchParams.get("search") || ""

    const [serviceClasses, setServiceClasses] = useState([])
    const [serviceClassMeta, setServiceClassMeta] = useState({})

    const [selectedFilterOption, setselectedFilterOption] = useState({
        date: null,
        patient: null,
        doctor: null,
        pic: null,
        procedure: null
    })

    const [filterDataOption, setFilterDataOption] = useState({
        date: new Date(),
        patient: [
            { value: 1, name: "Aji" },
            { value: 2, name: "Muhammad" },
            { value: 3, name: "Fauji" }
        ],
        doctor: [
            { value: 1, name: "Aji" },
            { value: 2, name: "Muhammad" },
            { value: 3, name: "Fauji" }
        ],
        pic: [
            { value: 1, name: "Aji" },
            { value: 2, name: "Muhammad" },
            { value: 3, name: "Fauji" }
        ],
        procedur: [
            { value: 1, name: "Aji" },
            { value: 2, name: "Muhammad" },
            { value: 3, name: "Fauji" }
        ]
    })

    const updateSearchParams = (params) => {
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams)
            Object.entries(params).forEach(([key, value]) => {
                if (value) {
                    newParams.set(key, value)
                } else {
                    newParams.delete(key)
                }
            })

            if (params.sortField && !params.sortOrder) {
                newParams.delete("sortField")
            }
            return newParams
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        updateSearchParams({
            sortField: sorter.field,
            sortOrder: sorter.order
        })
    }

    const handlePaginationChange = (page, pageSize) => {
        updateSearchParams({ page, pageSize })
    }

    const handlePageSizeChange = (pageSize) => {
        updateSearchParams({ page: 1, pageSize })
    }

    const handleSearchChange = (e) => {
        updateSearchParams({ search: e.target.value })
    }

    const disabledDate = (current) => {
        // Can not select days after today
        return current && current >= dayjs().endOf("day")
    }

    const { isFetching, isLoading } = useQuery({
        queryKey: ["serviceClasses", { page, pageSize, sortField, sortOrder, search }],
        queryFn: () =>
            getServiceClass({
                search,
                page,
                limit: pageSize,
                sort: sortOrder && sortOrder === "descend" ? `-${sortField}` : sortField
            }),
        onSuccess: (data) => {
            setServiceClasses(
                data.data.map((v) => ({
                    key: v.id,
                    ...v
                }))
            )
            setServiceClassMeta(data.meta)
        },
        onError: ({ response }) => {
            console.log(response)
        }
    })

    useEffect(() => {
        if (!searchParams.get("page")) {
            setSearchParams({ page: 1, pageSize: 10 })
        }
    }, [searchParams, setSearchParams])

    return (
        <div>
            <div className="sl-flex sl-items-center sl-gap-4 sl-mb-[32px]">
                <div className="sl-text-2xl sl-font-semibold">Evaluation</div>
            </div>

            <div className="sl-flex sl-flex-col sl-gap-2">
                <div className="sl-flex sl-justify-between sl-gap-4">
                    <div className="sl-flex sl-gap-2">
                        <RangePicker
                            // className="custom-range-picker hide-enddate"
                            className="sl-h-8"
                            format={"DD MMM YYYY"}
                            disabledDate={disabledDate}
                        />
                        <Select
                            className="sl-cursor-pointer sl-w-[120px]"
                            showSearch
                            placeholder="Patient"
                            value={selectedFilterOption.patient || null}
                            onChange={(value) => {
                                console.log("Patient", value)
                                setselectedFilterOption((prevFormData) => ({
                                    ...prevFormData,
                                    patient: value // Use the selected value from the dropdown
                                }))
                            }}
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            options={filterDataOption?.patient?.map((item) => ({
                                value: item.name, // tadinya idubah jadi name
                                label: item.name
                            }))}
                        />
                        <Select
                            className="sl-cursor-pointer sl-w-[120px]"
                            showSearch
                            placeholder="Doctor"
                            value={selectedFilterOption.doctor || null}
                            onChange={(value) => {
                                console.log("doctor", value)
                                setselectedFilterOption((prevFormData) => ({
                                    ...prevFormData,
                                    doctor: value // Use the selected value from the dropdown
                                }))
                            }}
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            options={filterDataOption?.doctor?.map((item) => ({
                                value: item.name, // tadinya idubah jadi name
                                label: item.name
                            }))}
                        />
                        <Select
                            className="sl-cursor-pointer sl-w-[120px]"
                            showSearch
                            placeholder="PIC"
                            value={selectedFilterOption.pic || null}
                            onChange={(value) => {
                                console.log("pic", value)
                                setselectedFilterOption((prevFormData) => ({
                                    ...prevFormData,
                                    pic: value // Use the selected value from the dropdown
                                }))
                            }}
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            options={filterDataOption?.pic?.map((item) => ({
                                value: item.name, // tadinya idubah jadi name
                                label: item.name
                            }))}
                        />
                        <Select
                            className="sl-cursor-pointer sl-w-[120px]"
                            showSearch
                            placeholder="Procedure"
                            value={selectedFilterOption.procedure || null}
                            onChange={(value) => {
                                console.log("procedure", value)
                                setselectedFilterOption((prevFormData) => ({
                                    ...prevFormData,
                                    procedure: value // Use the selected value from the dropdown
                                }))
                            }}
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            options={filterDataOption?.procedure?.map((item) => ({
                                value: item.name, // tadinya idubah jadi name
                                label: item.name
                            }))}
                        />
                    </div>
                    <Input
                        value={search}
                        placeholder="Search"
                        suffix={<SearchIcon />}
                        className="sl-w-[200px] sl-mb-[16px]"
                        onChange={handleSearchChange}
                    />
                </div>
                <div className="sl-flex sl-justify-between sl-gap-4 sl-mb-6">
                    <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                        <p className="sl-text-[gray]">Total Bill Estimation</p>
                        <p className="sl-text-3xl sl-font-semibold">{toCurrency(38443123)}</p>
                    </div>
                    <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                        <p className="sl-text-[gray]">Total Actual Invoices</p>
                        <p className="sl-text-3xl sl-font-semibold">{toCurrency(38443123)}</p>
                    </div>
                    <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                        <p className="sl-text-[gray]">Deviation</p>
                        <p className="sl-text-3xl sl-font-semibold sl-text-[#ce1919]">{toCurrency(38443123)} (12.5%)</p>
                    </div>
                </div>
                <Table
                    columns={columns}
                    dataSource={serviceClasses}
                    pagination={false}
                    loading={isLoading || isFetching}
                    onChange={handleTableChange}
                    footer={() => (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <div>
                                Display{" "}
                                <Select
                                    className="sl-w-[80px]"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                    options={[
                                        { value: 10, label: "10" },
                                        { value: 20, label: "20" },
                                        { value: 50, label: "50" },
                                        { value: 100, label: "100" }
                                    ]}
                                />{" "}
                                of {serviceClassMeta.total} data.
                            </div>
                            <Pagination
                                current={page}
                                pageSize={pageSize}
                                total={serviceClassMeta.total}
                                onChange={handlePaginationChange}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    )
}
