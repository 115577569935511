import React from 'react'

export default function ReceiptIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0003 9.16667H17.5003C17.9612 9.16667 18.3337 9.53917 18.3337 10V15C18.3337 16.3783 17.212 17.5 15.8337 17.5H4.16699C2.78866 17.5 1.66699 16.3783 1.66699 15V3.33333C1.66699 2.8725 2.03949 2.5 2.50033 2.5H14.167C14.6278 2.5 15.0003 2.8725 15.0003 3.33333V9.16667ZM3.33366 15C3.33366 15.46 3.70699 15.8333 4.16699 15.8333H13.4762C13.3845 15.5733 13.3337 15.2925 13.3337 15V4.16667H3.33366V15ZM15.8337 15.8333C16.2937 15.8333 16.667 15.46 16.667 15V10.8333H15.0003V15C15.0003 15.46 15.3737 15.8333 15.8337 15.8333ZM11.667 5.83333H5.00033V7.5H11.667V5.83333ZM11.667 9.16667H5.00033V10.8333H11.667V9.16667ZM11.667 12.5H9.16699V14.1667H11.667V12.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
