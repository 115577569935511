import axios from "axios";
import { checkTokenAuth, clearLocalStorageData } from "./AuthUtils";

const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const axiosGlobal = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

// Attach token dynamically to every request
axiosGlobal.interceptors.request.use(
  (config) => {
    const token = checkTokenAuth(); // Ensure the token is retrieved fresh for each request
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

// Handle response and errors globally
axiosGlobal.interceptors.response.use(
  (response) => {
    // Simply return the response data
    return response;
  },
  (error) => {
    if (error.response) {
      // Handle known response errors (e.g., 401 Unauthorized)
      if (error.response.status === 401) {
        clearLocalStorageData();
        window.location.href = "/login"; // Redirect to login page
      }
    } else if (error.request) {
      // The request was made but no response received
      console.error("No response received:", error.request);
    } else {
      // Something happened while setting up the request
      console.error("Error in request setup:", error.message);
    }
    return Promise.reject(error);
  }
);
