import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
    const [serviceClass, setServiceClass] = useState("");
    const [pageCreateEstimation, setPageCreateEstimation] = useState(true);
    const [idPriceEstimate, setIdPriceEstimate] = useState("");
    const [results, setResults] = useState(false);
    const [formData, setFormData] = useState({
        organization_id: "",
        tenant_id: "",
        medical_record_no: "",
        patient: "",
        disease_ids: [],
        procedure_id: "",
        doctor_id: "",
        referral_admission_type_id: "",
        operation_date: "",
        operation_start_time: "",
        operation_end_time: "",
        table_category: "",
        anesthesia_type: "",
        anesthesiologist_id: null,
        payment_type: "",
        insurance_id: null,
        note: "",
        service_class: "",
        admission_id: null,
        file: {
            filename: "",
            filepath: ""
        },
        other_services: [],
        nature_operation_id: "",

        // klasifikasi_id: "",
        // admission_id: "",
        // surgery_id: "",
        // doctor_name: "",
        // anesthesiologist: "",
        // nature_operation: "",
        
    });

    return (
        <GlobalContext.Provider
            value={{
                results, 
                setResults,
                formData, 
                setFormData,
                idPriceEstimate, 
                setIdPriceEstimate,
                serviceClass, 
                setServiceClass,
                pageCreateEstimation, 
                setPageCreateEstimation
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
};