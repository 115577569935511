import { Layout } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";

// import Logo from "../assets/images/logo.png";
import CloseIcon from "../assets/icons/CloseIcon";

const { Header, Content } = Layout;

export default function DetailLayout() {
  const location = useLocation();
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState({
    title: '',
    backTo: '/'
  });

  useEffect(() => {
    if (location.pathname === "/price-estimation/create") {
      setCurrentPage({
        title: "CREATE ESTIMATION",
        backTo: "/",
      });

      return;
    }

    // if (location.pathname === "/estimasi-daftar-harga/"+id) {
    //   setCurrentPage({
    //     title: "DETAIL ESTIMASI",
    //     backTo: "/price-estimation/create",
    //   });

    //   return;
    // }

    if (location.pathname.includes("/price-estimation") && !location.pathname.includes("/edit")) {
      setCurrentPage({
        title: "DETAIL ESTIMASI",
        backTo: "/",
      });

      return;
    }

    if (location.pathname.includes("/price-estimation") && location.pathname.includes("/edit")) {
      setCurrentPage({
        title: "EDIT ESTIMASI",
        backTo: `/price-estimation/${id}`,
      });

      return;
    }

    if (
      location.pathname.includes("/invoice") && id
    ) {
      setCurrentPage({
        title: "INVOICE",
        backTo: "/invoice",
      });

      return;
    }

    if (location.pathname === "/role-management/create-role") {
      setCurrentPage({
        title: "CREATE ROLE",
        backTo: "/role-management",
      });

      return;
    }
    
    if (location.pathname === `/role-management/edit-role/${id}`) {
      setCurrentPage({
        title: "EDIT ROLE",
        backTo: "/role-management",
      });

      return;
    }

    if (location.pathname.includes("/edit-price-estimation") && id) {
      setCurrentPage({
        title: "DETAIL ESTIMASI",
        backTo: "/",
      });

      return;
    }

    setCurrentPage({
      title: "",
      backTo: "/",
    });
  }, [location, id]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="sl-flex sl-items-center sl-justify-between sl-h-[48px] sl-py-0 sl-px-4 sl-fixed sl-w-full sl-z-[50]">
        <div className="sl-flex sl-items-center sl-text-white">
          <div className="sl-flex sl-items-center sl-gap-6">
            <NavLink to={currentPage.backTo}>
              <CloseIcon />
            </NavLink>
            <div className="sl-text-xl">{currentPage.title}</div>
          </div>
        </div>

        <div className="sl-flex sl-items-center sl-gap-4">
          {/* <img src={Logo} alt="Siloam Logo" className="sl-ml-2" /> */}
        </div>
      </Header>

      <Layout className="sl-pt-[48px]">
        <Content className="sl-bg-white">
          <Suspense fallback="...">
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}
