import React from 'react'

export default function InfoCircleIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 1.66663C5.40484 1.66663 1.6665 5.40496 1.6665 9.99996C1.6665 14.595 5.40484 18.3333 9.99984 18.3333C14.5948 18.3333 18.3332 14.595 18.3332 9.99996C18.3332 5.40496 14.5948 1.66663 9.99984 1.66663ZM9.99984 16.6666C6.324 16.6666 3.33317 13.6758 3.33317 9.99996C3.33317 6.32413 6.324 3.33329 9.99984 3.33329C13.6757 3.33329 16.6665 6.32413 16.6665 9.99996C16.6665 13.6758 13.6757 16.6666 9.99984 16.6666Z"
        fill="currentColor"
      />
      <path
        d="M9.1665 9.16671H10.8332V14.1667H9.1665V9.16671ZM9.1665 5.83337H10.8332V7.50004H9.1665V5.83337Z"
        fill="currentColor"
      />
    </svg>
  );
}
