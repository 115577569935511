import React from 'react'

export default function DeleteIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.8335 17.5C5.37516 17.5 4.9828 17.3368 4.65641 17.0104C4.33002 16.684 4.16683 16.2917 4.16683 15.8333V5C3.93072 5 3.7328 4.92014 3.57308 4.76042C3.41336 4.60069 3.3335 4.40278 3.3335 4.16667C3.3335 3.93056 3.41336 3.73264 3.57308 3.57292C3.7328 3.41319 3.93072 3.33333 4.16683 3.33333H7.50016C7.50016 3.09722 7.58002 2.89931 7.73975 2.73958C7.89947 2.57986 8.09738 2.5 8.3335 2.5H11.6668C11.9029 2.5 12.1009 2.57986 12.2606 2.73958C12.4203 2.89931 12.5002 3.09722 12.5002 3.33333H15.8335C16.0696 3.33333 16.2675 3.41319 16.4272 3.57292C16.587 3.73264 16.6668 3.93056 16.6668 4.16667C16.6668 4.40278 16.587 4.60069 16.4272 4.76042C16.2675 4.92014 16.0696 5 15.8335 5V15.8333C15.8335 16.2917 15.6703 16.684 15.3439 17.0104C15.0175 17.3368 14.6252 17.5 14.1668 17.5H5.8335ZM14.1668 5H5.8335V15.8333H14.1668V5ZM8.3335 14.1667C8.56961 14.1667 8.76752 14.0868 8.92725 13.9271C9.08697 13.7674 9.16683 13.5694 9.16683 13.3333V7.5C9.16683 7.26389 9.08697 7.06597 8.92725 6.90625C8.76752 6.74653 8.56961 6.66667 8.3335 6.66667C8.09738 6.66667 7.89947 6.74653 7.73975 6.90625C7.58002 7.06597 7.50016 7.26389 7.50016 7.5V13.3333C7.50016 13.5694 7.58002 13.7674 7.73975 13.9271C7.89947 14.0868 8.09738 14.1667 8.3335 14.1667ZM11.6668 14.1667C11.9029 14.1667 12.1009 14.0868 12.2606 13.9271C12.4203 13.7674 12.5002 13.5694 12.5002 13.3333V7.5C12.5002 7.26389 12.4203 7.06597 12.2606 6.90625C12.1009 6.74653 11.9029 6.66667 11.6668 6.66667C11.4307 6.66667 11.2328 6.74653 11.0731 6.90625C10.9134 7.06597 10.8335 7.26389 10.8335 7.5V13.3333C10.8335 13.5694 10.9134 13.7674 11.0731 13.9271C11.2328 14.0868 11.4307 14.1667 11.6668 14.1667Z"
        fill="currentColor"
      />
    </svg>
  );
}
