import React from 'react'

export default function MoreIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00004 11.6667C4.54171 11.6667 4.14935 11.5035 3.82296 11.1771C3.49657 10.8507 3.33337 10.4583 3.33337 10C3.33337 9.54167 3.49657 9.1493 3.82296 8.82292C4.14935 8.49653 4.54171 8.33333 5.00004 8.33333C5.45837 8.33333 5.85074 8.49653 6.17712 8.82292C6.50351 9.1493 6.66671 9.54167 6.66671 10C6.66671 10.4583 6.50351 10.8507 6.17712 11.1771C5.85074 11.5035 5.45837 11.6667 5.00004 11.6667ZM10 11.6667C9.54171 11.6667 9.14935 11.5035 8.82296 11.1771C8.49657 10.8507 8.33337 10.4583 8.33337 10C8.33337 9.54167 8.49657 9.1493 8.82296 8.82292C9.14935 8.49653 9.54171 8.33333 10 8.33333C10.4584 8.33333 10.8507 8.49653 11.1771 8.82292C11.5035 9.1493 11.6667 9.54167 11.6667 10C11.6667 10.4583 11.5035 10.8507 11.1771 11.1771C10.8507 11.5035 10.4584 11.6667 10 11.6667ZM15 11.6667C14.5417 11.6667 14.1493 11.5035 13.823 11.1771C13.4966 10.8507 13.3334 10.4583 13.3334 10C13.3334 9.54167 13.4966 9.1493 13.823 8.82292C14.1493 8.49653 14.5417 8.33333 15 8.33333C15.4584 8.33333 15.8507 8.49653 16.1771 8.82292C16.5035 9.1493 16.6667 9.54167 16.6667 10C16.6667 10.4583 16.5035 10.8507 16.1771 11.1771C15.8507 11.5035 15.4584 11.6667 15 11.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}
