import React from "react";
import RouterApp from "./routes";
import { ConfigProvider } from "antd";
import { App } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import { GlobalContextProvider } from './contexts/GlobalStateContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function SiloamApp() {
  const theme = {
    components: {
      Input: {
        activeBorderColor: "#FDB913",
        activeShadow: "0 0 0 2px rgba(255, 87, 34, 0.2)",
        hoverBorderColor: "#FDB913",
      },
      InputNumber: {
        activeBorderColor: "#FDB913",
        activeShadow: "0 0 0 2px rgba(255, 87, 34, 0.2)",
        hoverBorderColor: "#FDB913",
      },
      DatePicker: {
        activeBorderColor: "#FDB913",
        activeShadow: "0 0 0 2px rgba(255, 87, 34, 0.2)",
        hoverBorderColor: "#FDB913",
      },
      TimePicker: {
        activeBorderColor: "#FDB913",
        activeShadow: "0 0 0 2px rgba(255, 87, 34, 0.2)",
        hoverBorderColor: "#FDB913",
      },
      Pagination: {
        colorPrimary: "#FDB913",
        colorPrimaryBorder: "#FDB913",
        colorPrimaryHover: "#FDB913",
        colorText: "#111827",
      },
      Select: {
        colorPrimary: "#FDB913",
        colorPrimaryBorder: "#FDB913",
        colorPrimaryHover: "#FDB913",
        optionSelectedBg: "#efe0ba",
      },
      Breadcrumb: {
        itemColor: "#B65107",
        linkColor: "#B65107",
      },
      Radio: {
        colorPrimary: "#FDB913",
      },
    },
    token: {
      colorPrimary: "#354484",
      colorPrimaryHover: "#354484",
      colorPrimaryActive: "#354484",
    },
  };

  return (
    <QueryClientProvider client={queryClient}>
      <App>
        <ConfigProvider theme={theme}>
          <GlobalContextProvider>
            <RouterApp />
          </GlobalContextProvider>
        </ConfigProvider>
      </App>
    </QueryClientProvider>
  );
}

export default SiloamApp;
