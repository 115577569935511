import React from 'react';
import { Navigate } from 'react-router-dom';
import { checkTokenAuth } from '../utils/AuthUtils';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = checkTokenAuth();
  const isAuthorized = isAuthenticated;

  return isAuthorized ? children  : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
