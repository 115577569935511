import React from "react"

export default function RoleManagementIcon({ className }) {
    return (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.666656 0.5H2.33332V15.5H0.666656V0.5ZM15.6667 0.5H17.3333V15.5H15.6667V0.5ZM3.16666 8.83333H4.83332V9.66667H6.49999V8.83333H8.16666V9.66667H9.83332V8.83333H13.1667V9.66667H14.8333V8.83333V7.16667V6.33333H13.1667V7.16667H9.83332V6.33333H8.16666V7.16667H6.49999V6.33333H4.83332V7.16667H3.16666V8.83333ZM3.16666 1.33333V2.16667V3.83333V4.66667H4.83332V3.83333H11.5V4.66667H13.1667V3.83333H14.8333V2.16667H13.1667V1.33333H11.5V2.16667H4.83332V1.33333H3.16666ZM3.16666 12.1667V13.8333V14.6667H4.83332V13.8333H6.49999V14.6667H8.16666V13.8333H14.8333V12.1667H8.16666V11.3333H6.49999V12.1667H4.83332V11.3333H3.16666V12.1667Z"
                fill="currentColor"
            />
        </svg>
    )
}
