import React from 'react'

export default function CloseIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 11.1667L5.91659 15.25C5.76381 15.4028 5.56936 15.4792 5.33325 15.4792C5.09714 15.4792 4.9027 15.4028 4.74992 15.25C4.59714 15.0972 4.52075 14.9028 4.52075 14.6667C4.52075 14.4306 4.59714 14.2361 4.74992 14.0833L8.83325 10L4.74992 5.91668C4.59714 5.7639 4.52075 5.56945 4.52075 5.33334C4.52075 5.09723 4.59714 4.90279 4.74992 4.75001C4.9027 4.59723 5.09714 4.52084 5.33325 4.52084C5.56936 4.52084 5.76381 4.59723 5.91659 4.75001L9.99992 8.83334L14.0833 4.75001C14.236 4.59723 14.4305 4.52084 14.6666 4.52084C14.9027 4.52084 15.0971 4.59723 15.2499 4.75001C15.4027 4.90279 15.4791 5.09723 15.4791 5.33334C15.4791 5.56945 15.4027 5.7639 15.2499 5.91668L11.1666 10L15.2499 14.0833C15.4027 14.2361 15.4791 14.4306 15.4791 14.6667C15.4791 14.9028 15.4027 15.0972 15.2499 15.25C15.0971 15.4028 14.9027 15.4792 14.6666 15.4792C14.4305 15.4792 14.236 15.4028 14.0833 15.25L9.99992 11.1667Z"
        fill="currentColor"
      />
    </svg>
  );
}
