import React from 'react';
import { Modal } from 'antd';
import { useQuery } from 'react-query';
import { getDetailRole } from '../../api/RoleManagement';

const ModalDetailRole = ({ openModalDetailRole, setOpenModalDetailRole, idRole }) => {
    const { data } = useQuery(
        ['getDetailRole', idRole],
        () => getDetailRole(idRole),
        {
            enabled: !!idRole,
        }
    );

    return (
        <Modal
            title={<span className="sl-font-semibold sl-text-xl">Detail Role</span>}
            centered
            open={openModalDetailRole}
            onCancel={() => setOpenModalDetailRole(false)}
            footer={null}
            width="400px"
            className="sl-my-5"
        >
            <div>
                <div className="sl-space-y-3 sl-my-7">
                    <div>
                        <label>Role Name</label>
                        <p className="sl-font-semibold sl-text-base">{data?.data?.role}</p>
                    </div>
                    <div className="sl-w-full sl-flex sl-flex-wrap sl-space-y-2">
                        <div className="sl-w-full">Menu</div>
                        <div className="sl-w-full sl-flex sl-flex-wrap sl-gap-2">
                            {
                                data?.data?.menus 
                                ?
                                    data?.data?.menus?.map((item, index) => (
                                        <span 
                                            key={index}
                                            className="sl-w-auto sl-font-semibold sl-text-base sl-bg-border-primary-light sl-p-2 sl-rounded-md"
                                        >
                                            {item?.name}
                                        </span>
                                    ))
                                : 
                                    <span className="sl-font-semibold sl-text-lg">-</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ModalDetailRole;
