import React from 'react'

export default function ProfileIcon({ className }) {
  return (
    <svg 
      className={className}
      width="16" 
      height="17" 
      viewBox="0 0 16 17" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M8 0.666687C5.7025 0.666687 3.83333 2.53585 3.83333 4.83335C3.83333 7.13085 5.7025 9.00002 8 9.00002C10.2975 9.00002 12.1667 7.13085 12.1667 4.83335C12.1667 2.53585 10.2975 0.666687 8 0.666687ZM8 7.33335C6.62167 7.33335 5.5 6.21169 5.5 4.83335C5.5 3.45502 6.62167 2.33335 8 2.33335C9.37833 2.33335 10.5 3.45502 10.5 4.83335C10.5 6.21169 9.37833 7.33335 8 7.33335ZM15.5 16.5V15.6667C15.5 12.4509 12.8825 9.83335 9.66667 9.83335H6.33333C3.11667 9.83335 0.5 12.4509 0.5 15.6667V16.5H2.16667V15.6667C2.16667 13.3692 4.03583 11.5 6.33333 11.5H9.66667C11.9642 11.5 13.8333 13.3692 13.8333 15.6667V16.5H15.5Z" 
        fill="currentColor"
      />
    </svg>
  );
}
