import React from "react"

export default function UserManagementIcon({ className }) {
    return (
        <svg 
            width="16" 
            height="17" 
            viewBox="0 0 16 17" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path 
                d="M8 0.666016C5.7025 0.666016 3.83333 2.53518 3.83333 4.83268C3.83333 7.13018 5.7025 8.99935 8 8.99935C10.2975 8.99935 12.1667 7.13018 12.1667 4.83268C12.1667 2.53518 10.2975 0.666016 8 0.666016ZM8 7.33268C6.62167 7.33268 5.5 6.21102 5.5 4.83268C5.5 3.45435 6.62167 2.33268 8 2.33268C9.37833 2.33268 10.5 3.45435 10.5 4.83268C10.5 6.21102 9.37833 7.33268 8 7.33268ZM15.5 16.4993V15.666C15.5 12.4502 12.8825 9.83268 9.66667 9.83268H6.33333C3.11667 9.83268 0.5 12.4502 0.5 15.666V16.4993H2.16667V15.666C2.16667 13.3685 4.03583 11.4993 6.33333 11.4993H9.66667C11.9642 11.4993 13.8333 13.3685 13.8333 15.666V16.4993H15.5Z" 
                fill="currentColor"
            />
        </svg>

    )
}
