import { Button, Modal, Result, Table } from "antd"
import React, { useState } from "react"
import DeleteIcon from "../../assets/icons/DeleteIcon.js"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import FileIcon from "../../assets/icons/FileIcon.js"
import LinkExternalIcon from "../../assets/icons/LinkExternalIcon.js"
import InfoCircleIcon from "../../assets/icons/InfoCircleIcon.js"
import DownloadIcon from "../../assets/icons/DownloadIcon.js"
import EllipsIcon from "../../assets/icons/EllipsIcon.js"
import EditIcon from "../../assets/icons/EditIcon.js"
import { NavLink, useParams } from "react-router-dom"
import { findDuplicates, toCurrency } from "../../utils/String.js"
import { getResultPriceEstimation } from "../../api/PriceEstimation.js"
import { useQuery } from "react-query"
import dayjs from "dayjs"
// import { downloadFile } from "../../utils/DownloadByUrl.js"
import "./Evaluation.css"
import ModalDetailRefrensi from "../../components/PriceEstimation/ModalDetailRefrensi.js"

export default function EvaluationDetail() {
    const { id } = useParams()

    const [logModal, setLogModal] = useState(false)
    const [changesModal, setChangesModal] = useState(false)
    const [changesData, setChangesData] = useState("")
    const [EstimateData, setEstimateData] = useState({})
    const [MedicalToolsData, setMedicalToolsData] = useState([])
    const [InvoiceData, setInvoiceData] = useState([])
    const [ComponentData, setComponentData] = useState([])
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [isModalDetailRefrensi, setIsModalDetailRefrensi] = useState(false)

    const [file, setFile] = useState({
        filename: "",
        path: ""
    })

    const { isFetching, isLoading } = useQuery({
        queryKey: ["estimateDetail"],
        queryFn: () => getResultPriceEstimation(id),
        onSuccess: (data) => {
            setEstimateData(data.data)
            setInvoiceData(
                data?.data?.invoices?.map((item, index) => ({
                    key: index,
                    id: item?.id,
                    invoiceNo: item?.invoice_no,
                    invoiceDate: item?.invoice_date,
                    admissionDate: item?.administration_date,
                    patient: item?.patient,
                    surgery: item?.operation_name,
                    amount: item?.total
                }))
            )
            setFile({ ...data?.data?.file, filename: data?.data?.file?.filename, path: data?.data?.file?.filepath })
            setComponentData(
                data.data?.components
                    .map((component, i) => ({
                        ...component,
                        key: i + 1,
                        id: i + 1,
                        position:
                            component.id === "FEE_DOCTOR_OPERATOR"
                                ? 1
                                : component.id === "ANAESTHESIA"
                                ? 2
                                : component.id === "ROOM_RENT"
                                ? 3
                                : component.id === "MEDICAL_TOOL"
                                ? 4
                                : component.id === "MEDICINE"
                                ? 5
                                : 6
                    }))
                    .sort((a, b) => a.position - b.position)
                    .map((v, i) => ({ ...v, index: i + 1 }))
            )
            setExpandedRowKeys(data?.data?.components.map((d, i) => i + 1))

            let medicTools = data.data?.components.filter((component, i) => component.id === "DOCTOR_MEDICAL_TOOL")
            if (medicTools.length > 0) {
                medicTools = medicTools[0]?.items
                    ?.filter((item) => item.source === "Master Data")
                    ?.map((dt) => `${dt.name} ( ${dt.qty}x )`)

                setMedicalToolsData(medicTools)
            }
        },
        onError: ({ response }) => {
            console.log(response)
        }
    })

    const openChangesModal = (data) => {
        setChangesData(data)
        setChangesModal(true)
    }

    const closeChangesModal = () => {
        setChangesModal(false)
        setChangesData("")
    }

    const handleDownloadFile = () => {
        if (file?.path) {
            window.open(file.path, "_blank")
        } else {
            console.error("File path is invalid")
        }
    }

    const showModalDetailRefrensi = () => {
        setIsModalDetailRefrensi(!isModalDetailRefrensi)
    }

    const handleExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeys((prev) => [...prev, record.key])
        } else {
            setExpandedRowKeys((prev) => prev.filter((pre) => pre !== record.key))
        }
    }

    const columns = [
        {
            title: "No",
            dataIndex: "index",
            width: "61px",
            key: "index"
        },
        Table.EXPAND_COLUMN,
        {
            title: "Nama Biaya",
            dataIndex: "alias",
            key: "alias",
            render: (_, record) => {
                return (
                    <div>
                        <p>{record.alias}</p>
                        {/* {record.position < 4 && (
                            <span className="sl-text-[darkgray] sl-text-xs">
                                {record.items[0]?.name} - {record.items[0]?.qty} {record.id === 3 ? "Day" : "Hours"}
                            </span>
                        )} */}
                    </div>
                )
            }
        },
        // {
        //     title: "Data Source",
        //     dataIndex: "alias",
        //     key: "alias",
        //     render: (_, record) => (
        //         <>
        //             {record.items.length > 1 ? (
        //                 <p>
        //                     {findDuplicates(
        //                         record.items.map((item) => item.source)?.filter((item) => item !== "")
        //                     ).join(", ")}
        //                 </p>
        //             ) : (
        //                 <p>Master Data</p>
        //             )}
        //         </>
        //     )
        // },
        {
            title: "Amount",
            dataIndex: "total_amount",
            key: "total_amount",
            align: "right",
            render: (_, record) => (
                <div className="sl-flex sl-items-center sl-gap-2 sl-justify-end">
                    {record.isUpdated && (
                        <button onClick={() => openChangesModal(record)}>
                            <InfoCircleIcon />
                        </button>
                    )}
                    {toCurrency(record.total_amount)}
                </div>
            )
        }
    ]

    return (
        <div className="sl-w-full sl-min-w-[500px] sl-max-w-[1600px] sl-py-12 sl-p-10 sl-space-y-7 sl-px-[auto] sl-mx-[auto]">
            <div className="sl-flex sl-justify-between sl-gap-4 sl-mb-6">
                <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                    <p className="sl-text-[gray]">Total Bill Estimation</p>
                    <p className="sl-text-3xl sl-font-semibold">{toCurrency(38443123)}</p>
                </div>
                <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                    <p className="sl-text-[gray]">Total Actual Invoices</p>
                    <p className="sl-text-3xl sl-font-semibold">{toCurrency(38443123)}</p>
                </div>
                <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                    <p className="sl-text-[gray]">Deviation</p>
                    <p className="sl-text-3xl sl-font-semibold sl-text-[#ce1919]">{toCurrency(38443123)} (12.5%)</p>
                </div>
            </div>

            <div className="sl-border sl-border-border-primary-light sl-rounded sl-p-4 sl-space-y-4">
                <div className="sl-grid sl-grid-cols-4">
                    <div className="sl-flex sl-flex-col">
                        <div className="sl-text-text-secondary">Invoice No</div>
                        <div className="sl-text-text-primary sl-font-semibold">IIV240810030</div>
                    </div>
                    <div className="sl-flex sl-flex-col">
                        <div className="sl-text-text-secondary">Invoice Date</div>
                        <div className="sl-text-text-primary sl-font-semibold">01 Aug 2024 12:26</div>
                    </div>
                    <div className="sl-flex sl-flex-col">
                        <div className="sl-text-text-secondary">Admission Date</div>
                        <div className="sl-text-text-primary sl-font-semibold">26 Jul 2024 09: 33</div>
                    </div>
                    <div className="sl-flex sl-flex-col">
                        <div className="sl-text-text-secondary">Discharge</div>
                        <div className="sl-text-text-primary sl-font-semibold">01 Aug 2024 07:10</div>
                    </div>
                </div>
            </div>
            <div className="sl-border sl-border-border-primary-light sl-rounded sl-p-4 sl-space-y-4">
                <div className="sl-mb-4">
                    <div className="sl-grid sl-grid-cols-4">
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Electronic Medic Record</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {EstimateData?.medical_record_no}
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Nama Pasien</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.patient}</div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Nama Prosedur</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.operation?.name}</div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Nama Dokter Operator</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.doctor_name}</div>
                        </div>
                    </div>
                </div>
                <div className="sl-mb-4">
                    <div className="sl-grid sl-grid-cols-4">
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Tanggal Operasi</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dayjs(EstimateData?.operation_date).format("DD MMM YYYY")},{" "}
                                {EstimateData?.operation_start_time?.split(":")[0]}:
                                {EstimateData?.operation_start_time?.split(":")[1]} -{" "}
                                {EstimateData?.operation_end_time?.split(":")[0]}:
                                {EstimateData?.operation_end_time?.split(":")[1]}
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Kelas/Kategori</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.table_category}</div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Jenis Anestesi</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.anesthesia_type}</div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Admission Type</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.anesthesia_type}</div>
                        </div>
                    </div>
                </div>
                <div className="sl-mb-4">
                    <div className="sl-grid sl-grid-cols-4">
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Sifat Operasi</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.nature_operation}</div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Penanggung Jawab</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.payment_type}</div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Kelas Perawatan</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.service_class}</div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Keterangan</div>
                            <div className="sl-text-text-primary sl-font-semibold">{EstimateData?.note}</div>
                        </div>
                    </div>
                </div>

                <div className="sl-mb-4">
                    <div className="sl-grid sl-grid-cols-4">
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Medical Notes</div>
                            <div>
                                <button
                                    className="sl-flex sl-gap-2 sl-cursor-pointer sl-text-[blue]"
                                    onClick={handleDownloadFile}
                                >
                                    Download <DownloadIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sl-grid sl-grid-cols-2 sl-gap-4">
                <div className="price-estimate-table sl-grid sl-gap-6">
                    <div className="sl-text-2xl sl-font-semibold ">Bill Estimasi</div>
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={ComponentData}
                        className="items-table"
                        footer={() => (
                            <div className="sl-flex sl-justify-between sl-items-center sl-text-text-primary sl-font-semibold">
                                <div>Total Biaya Operasi</div>
                                <div>{toCurrency(EstimateData?.estimation)}</div>
                            </div>
                        )}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div className="sl-w-full sl-flex sl-flex-wrap sl-space-y-2">
                                    {record?.items?.map((coll, index) => (
                                        <div
                                            key={index}
                                            className="sl-w-full sl-flex sl-justify-around sl-items-center"
                                        >
                                            <div className="sl-w-3/4 sl-flex sl-flex-col sl-pl-[4rem] sl-text-xs sl-leading-[21px]">
                                                <div className="sl-flex sl-gap-1">
                                                    <span className="sl-font-medium sl-inline">{coll?.name} </span>
                                                </div>
                                                <p className="sl-text-[darkgray]"> {coll?.reason}</p>
                                            </div>
                                            <div className="sl-w-1/4 sl-text-sm sl-flex sl-items-center sl-justify-end sl-gap-1">
                                                {coll.update_reason && (
                                                    <button onClick={() => openChangesModal(record, index)}>
                                                        <InfoCircleIcon />
                                                    </button>
                                                )}
                                                {toCurrency(coll.amount)} ( {coll?.qty}x )
                                                {/* <button onClick={() => openEditModal(record, index)}>
                                                    <EditIcon />
                                                </button> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ),
                            rowExpandable: (record) => record.items.length > 0,
                            expandedRowKeys,
                            onExpand: handleExpand,
                            expandIcon: ({ expanded, onExpand, record }) => (
                                <>
                                    {record.items.length > 0 && (
                                        <span className="sl-cursor-pointer" onClick={(e) => onExpand(record, e)}>
                                            {expanded ? (
                                                <UpOutlined style={{ fontSize: "10px" }} />
                                            ) : (
                                                <DownOutlined style={{ fontSize: "10px" }} />
                                            )}
                                        </span>
                                    )}
                                </>
                            )
                        }}
                    />
                    <div className="sl-p-4 sl-bg-surface-primary-light sl-rounded sl-space-y-4">
                        <div className="sl-text-xs sl-text-text-primary">
                            {EstimateData?.summary?.split("\n").map((str) => {
                                const formattedStr = str.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                                return <div className="sl-mt-3" dangerouslySetInnerHTML={{ __html: formattedStr }} />
                            })}
                        </div>
                    </div>
                </div>
                <div className="price-estimate-table sl-grid sl-gap-6">
                    <div className="sl-text-2xl sl-font-semibold ">Actual Invoice</div>
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={ComponentData}
                        className="items-table"
                        footer={() => (
                            <div className="sl-flex sl-justify-between sl-items-center sl-text-text-primary sl-font-semibold">
                                <div>Total Biaya Operasi</div>
                                <div>{toCurrency(EstimateData?.estimation)}</div>
                            </div>
                        )}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div className="sl-w-full sl-flex sl-flex-wrap sl-space-y-2">
                                    {record?.items?.map((coll, index) => (
                                        <div
                                            key={index}
                                            className="sl-w-full sl-flex sl-justify-around sl-items-center"
                                        >
                                            <div className="sl-w-3/4 sl-flex sl-flex-col sl-pl-[4rem] sl-text-xs sl-leading-[21px]">
                                                <div className="sl-flex sl-gap-1">
                                                    <span className="sl-font-medium sl-inline">{coll?.name} </span>
                                                </div>
                                                <p className="sl-text-[darkgray]"> {coll?.reason}</p>
                                            </div>
                                            <div className="sl-w-1/4 sl-text-sm sl-flex sl-items-center sl-justify-end sl-gap-1">
                                                {coll.update_reason && (
                                                    <button onClick={() => openChangesModal(record, index)}>
                                                        <InfoCircleIcon />
                                                    </button>
                                                )}
                                                {toCurrency(coll.amount)} ( {coll?.qty}x )
                                                {/* <button onClick={() => openEditModal(record, index)}>
                                                    <EditIcon />
                                                </button> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ),
                            rowExpandable: (record) => record.items.length > 0,
                            expandedRowKeys,
                            onExpand: handleExpand,
                            expandIcon: ({ expanded, onExpand, record }) => (
                                <>
                                    {record.items.length > 0 && (
                                        <span className="sl-cursor-pointer" onClick={(e) => onExpand(record, e)}>
                                            {expanded ? (
                                                <UpOutlined style={{ fontSize: "10px" }} />
                                            ) : (
                                                <DownOutlined style={{ fontSize: "10px" }} />
                                            )}
                                        </span>
                                    )}
                                </>
                            )
                        }}
                    />
                    <div className="sl-p-4 sl-bg-surface-primary-light sl-rounded sl-space-y-4">
                        <div className="sl-text-xs sl-text-text-primary">
                            {EstimateData?.summary?.split("\n").map((str) => {
                                const formattedStr = str.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                                return <div className="sl-mt-3" dangerouslySetInnerHTML={{ __html: formattedStr }} />
                            })}
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                title={<div className="sl-text-xl">Detail Perubahan</div>}
                centered
                open={changesModal}
                onCancel={() => closeChangesModal()}
                footer={null}
                width="400px"
            >
                <div className="sl-space-y-3 sl-pt-4">
                    <div className="sl-grid sl-grid-cols-2">
                        <div>
                            <div className="sl-text-text-secondary">Edited By</div>
                            <div className="sl-text-text-primary sl-font-semibold">{changesData.updatedBy}</div>
                        </div>
                        <div>
                            <div className="sl-text-text-secondary">Waktu Perubahan</div>
                            <div className="sl-text-text-primary sl-font-semibold">{changesData.updatedAt}</div>
                        </div>
                    </div>

                    <div>
                        <div className="sl-text-text-secondary">Amount</div>
                        <div className="sl-text-text-primary sl-font-semibold">{toCurrency(changesData.amount)}</div>
                    </div>

                    <div>
                        <div className="sl-text-text-secondary">Alasan Perubahan</div>
                        <div className="sl-text-text-primary sl-font-semibold">{changesData.updatedReason}</div>
                    </div>
                </div>
            </Modal>
            <ModalDetailRefrensi
                isModalOpen={isModalDetailRefrensi}
                setIsModalOpen={setIsModalDetailRefrensi}
                dataSource={InvoiceData}
            />
        </div>
    )
}

const dummyData = {
    items: [
        {
            id: 1,
            name: "Fee Dokter Operator",
            amount: 12397875,
            isUpdated: false,
            updatedBy: "Bayu",
            updatedAt: "08/08/2024, 20:24",
            updatedReason: "Penyesuaian harga dengan pasar."
        },
        {
            id: 2,
            name: "Fee Dokter Operator",
            amount: 12397875,
            isUpdated: true,
            updatedBy: "Bayu",
            updatedAt: "08/08/2024, 20:24",
            updatedReason: "Penyesuaian harga dengan pasar."
        },
        {
            id: 3,
            name: "Fee Dokter Operator",
            amount: 12397875,
            isUpdated: false,
            updatedBy: "Bayu",
            updatedAt: "08/08/2024, 20:24",
            updatedReason: "Penyesuaian harga dengan pasar."
        },
        {
            id: 4,
            name: "Fee Dokter Operator",
            amount: 12397875,
            isUpdated: false,
            updatedBy: "Bayu",
            updatedAt: "08/08/2024, 20:24",
            updatedReason: "Penyesuaian harga dengan pasar."
        },
        {
            id: 5,
            name: "Fee Dokter Operator",
            amount: 12397875,
            isUpdated: true,
            updatedBy: "Bayu",
            updatedAt: "08/08/2024, 20:24",
            updatedReason: "Penyesuaian harga dengan pasar."
        },
        {
            id: 6,
            name: "Fee Dokter Operator",
            amount: 12397875,
            isUpdated: false,
            updatedBy: "Bayu",
            updatedAt: "08/08/2024, 20:24",
            updatedReason: "Penyesuaian harga dengan pasar."
        },
        {
            id: 7,
            name: "Fee Dokter Operator",
            amount: 12397875,
            isUpdated: false,
            updatedBy: "Bayu",
            updatedAt: "08/08/2024, 20:24",
            updatedReason: "Penyesuaian harga dengan pasar."
        }
    ],
    logs: [
        {
            date: "21/09/2024 14:34",
            description: "Febria Purwanto mengedit harga Fee Dokter Operator menjadi Rp 50.000.000."
        },
        {
            date: "21/09/2024 14:34",
            description: "Febria Purwanto mengedit harga Fee Dokter Operator menjadi Rp 50.000.000."
        },
        {
            date: "21/09/2024 14:34",
            description: "Febria Purwanto mengedit harga Fee Dokter Operator menjadi Rp 50.000.000."
        },
        {
            date: "21/09/2024 14:34",
            description: "Febria Purwanto mengedit harga Fee Dokter Operator menjadi Rp 50.000.000."
        },
        {
            date: "21/09/2024 14:34",
            description: "Febria Purwanto mengedit harga Fee Dokter Operator menjadi Rp 50.000.000."
        },
        {
            date: "21/09/2024 14:34",
            description: "Febria Purwanto mengedit harga Fee Dokter Operator menjadi Rp 50.000.000."
        },
        {
            date: "21/09/2024 14:34",
            description: "Febria Purwanto mengedit harga Fee Dokter Operator menjadi Rp 50.000.000."
        }
    ]
}
